import { Text } from '@chakra-ui/layout'
import { Box } from '../Box'
import { Divider } from '../Divider'
import Link from 'next/link'
import React from 'react'
import { PostRender } from '../../types'
import { ArticleSectionItem } from '../ArticleSection'
import { Column, Columns } from '../Columns'
import { AppContainer } from '../AppContainer'
import { ArticleItemPostDate } from '../ArticleItemPostDate'
import Image from 'next/image'

export type HighLightSectionProps = {
  title: string
  icon: string
  list: PostRender[]
}

export function HighLightSectionFirstItem(props: PostRender) {
  const { title, category, image, categoryUrl, url, caption, author, coAuthors, id, date } = props
  const authors = [author, ...coAuthors]

  return (
    <Box as='article'>
      <Box>
        <Link href={url} prefetch={false}>
          <a href={url} title={title} target='_blank' rel='noreferrer'>
            <Image src={image} alt={caption || title} width={1080} height={500} layout='responsive' priority={true} />
          </a>
        </Link>
      </Box>
      <Link href={url} prefetch={false}>
        <a href={categoryUrl} title={category}>
          <Text
            textTransform='uppercase'
            textAlign={{
              base: 'center'
            }}
            textStyle='highlightTitleCategory'
            mt={{
              base: '40px',
              md: '50px'
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Text>
        </a>
      </Link>
      <Link href={url} prefetch={false}>
        <a href={url} title={title} target='_blank' rel='noreferrer'>
          <Text
            mt={{
              base: '20px',
              md: '10px'
            }}
            textAlign={{
              base: 'center'
            }}
            as='h2'
            textStyle='highlightTitle'
          >
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Text>
        </a>
      </Link>
      <ArticleItemPostDate
        justifyContent='center'
        color='articleItemPostDate'
        authors={authors}
        date={date}
        id={id}
        isSameLine
      />
    </Box>
  )
}

function HighLightSection(props: HighLightSectionProps) {
  const { list, icon, title } = props
  const firstItem = list[0]
  const otherItems = list.filter((s, i) => {
    return i !== 0
  })
  return (
    <Box
      as='section'
      bg='bgFeature'
      color='textFeature'
      pt={{
        base: '40px',
        md: '55px'
      }}
    >
      <AppContainer pt='0'>
        <Box as='header'>
          <Box textStyle='sectionTitleFeature' as='h2' display='flex' alignItems='center'>
            <Box mr='16px'>
              <Image
                width={26}
                height={26}
                objectFit='contain'
                src={icon}
                alt={`Icon for ${title}`}
                style={{ marginRight: 16 }}
              />
            </Box>
            <Box as='span' dangerouslySetInnerHTML={{ __html: title }} color='sectionTitleFeature' />
          </Box>
        </Box>
        <Columns collapse='lg' gutter='0px' mt='30px'>
          <Column fWidth='65%' width='100%'>
            <Box
              width='100%'
              paddingRight={{
                base: 0,
                lg: 55
              }}
            >
              <HighLightSectionFirstItem {...firstItem} />
            </Box>
          </Column>
          <Column
            mt={{
              base: '80px',
              lg: 0
            }}
          >
            <Box
              borderLeft={{
                base: 'none',
                lg: '1px solid'
              }}
              borderColor={{
                base: 'none',
                lg: 'borderFeature'
              }}
              pl={{
                base: '0',
                lg: '35px'
              }}
              pb={{
                base: 0,
                lg: '80px'
              }}
            >
              <Divider borderColor='borderFeature' />
              <Box as='ul' listStyleType='none'>
                {otherItems.map(p => {
                  return (
                    <Box as='li' key={p.id}>
                      <ArticleSectionItem
                        variant='highlight'
                        title={p.title}
                        url={p.url}
                        categoryUrl={p.categoryUrl}
                        category={p.category}
                        {...p}
                      />
                      <Divider borderColor='borderFeature' />
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Column>
        </Columns>
      </AppContainer>
    </Box>
  )
}

export { HighLightSection }
