import config from '@krasia/core/config'

export default config

export const headerLinks = {
  login: {
    text: '登录',
    url: config.paths.signIn
  },
  subscribe: {
    text: '订阅',
    url: config.paths.subscribe
  }
}

export const footerLinks = [
  {
    link: config.paths.about,
    text: '关于'
  },
  {
    link: config.paths.subscribe,
    text: '订阅'
  },
  {
    link: config.paths.jobs(1),
    text: '事业'
  },
  {
    link: config.paths.privacy,
    text: '隐私政策'
  }
]

export const headerTexts = {
  openMenu: '菜单',
  closeMenu: '关',
  searchPlaceHolder: '输入搜索',
  search: '搜索'
}

export const footerTexts = {
  headingSubscribe: '订阅我们的每周新闻',
  emailPlaceholder: '电子邮件地址',
  emailError: '必须是有效的电子邮件',
  subscribeSuccess: '现在订阅',
  subscribeFailure: '订阅失败，请稍后再试！',
  rssFeed: 'RSS订阅',
  feedly: '跟随饲料',
  googleNews: '在Google新闻上关注',
  description: '一家数字媒体公司，报道世界新兴市场中最有前途的技术驱动型业务和趋势。',
  reserved: '2020版权所有'
}
