"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClosestValue = void 0;
var utils_1 = require("@chakra-ui/utils");
function getClosestValue(values, breakpoint) {
    var index = Object.keys(values).indexOf(breakpoint);
    if (index !== -1) {
        return values[breakpoint];
    }
    var stopIndex = utils_1.breakpoints.indexOf(breakpoint);
    while (stopIndex >= 0) {
        var key = utils_1.breakpoints[stopIndex];
        if (values[key] != null) {
            index = stopIndex;
            break;
        }
        stopIndex--;
    }
    if (index !== -1) {
        var key = utils_1.breakpoints[index];
        return values[key];
    }
}
exports.getClosestValue = getClosestValue;
