import { Text } from '@chakra-ui/layout'
import { IconButton, IconButtonProps } from '@chakra-ui/button'
import { useKeenSlider } from 'keen-slider/react'
import Link from 'next/link'
import React, { ReactElement } from 'react'
import { PostRender } from '../../types'
import { AppContainer } from '../AppContainer'
import { Box } from '../Box'
import { SeeAllButton } from '../ArticleSection'
import { Prev, Next } from '../../icons'
import Play from '../../icons/Play'
import { ArticleItemPostDate } from '../ArticleItemPostDate'
import { Divider } from '../Divider'
import { useMediaQuery } from '@chakra-ui/react'
import Image from 'next/image'

export type CollectionSectionProps = {
  slug: string
  seeAllUrl?: string
  list: PostRender[]
  iconImage?: string | ReactElement
  title: string
}

type CueProps = Partial<IconButtonProps> & {
  arrow: 'prev' | 'next'
}

function Cue({ arrow, ...otherProps }: CueProps) {
  const arrowProps: IconButtonProps = {
    opacity: 0.8,
    'aria-label': 'Previous',
    fontSize: '24px',
    backgroundColor: 'rgba(0,0,0,0.4)',
    color: 'white',
    width: '1.5em',
    height: '1.5em',

    _hover: {
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,0.6)'
    }
  }

  return (
    <IconButton {...arrowProps} {...otherProps}>
      {arrow === 'prev' ? <Prev /> : <Next />}
    </IconButton>
  )
}

export function CollectionSection({ list, iconImage, title, seeAllUrl, slug }: CollectionSectionProps) {
  // const device = useBreakpointValue<string>({ base: 'mobile', md: 'lower-desktop', lg: 'desktop' }) || 'desktop'
  const [isMobile, isMediumDesktop, isDesktop] = useMediaQuery([
    '(max-width: 768px)',
    '(max-width: 1024px)',
    '(min-width: 1025px)'
  ])
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState(false)
  let slidePer = 1.1
  let spacing = 20
  const isVideos = slug === 'videos'

  if (isMobile) {
    slidePer = 1.1
    spacing = 20
  } else if (isMediumDesktop) {
    slidePer = 2.1
    spacing = 30
  }

  if (isDesktop) {
    slidePer = 3.2
    spacing = 50
  }

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: slidePer,
      spacing: spacing
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    created() {
      setLoaded(true)
    }
  })

  const handleCue = (arrow: 'next' | 'prev') => {
    if (instanceRef) {
      if (arrow === 'next') {
        instanceRef.current.next()
      } else {
        instanceRef.current.prev()
      }
    }
  }

  return (
    <AppContainer py='40px' pr={{ base: '0px', sm: '50px', md: '50px' }}>
      <Box as='section'>
        <Box as='header'>
          <Text as='h2' textStyle='sectionTitle' display='flex' mb='40px' alignItems='center' position={'relative'}>
            <a
              href={seeAllUrl}
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                width: '300px',
                zIndex: 1,
                textIndent: '-9999px',
                textDecoration: 'none',
                cursor: 'pointer'
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </a>
            {iconImage && typeof iconImage === 'string' ? (
              <Box mr='16px'>
                <Image objectFit='contain' width={26} height={26} alt='' src={iconImage} />
              </Box>
            ) : (
              <Box height='1em' mr='16px'>
                {iconImage}
              </Box>
            )}
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Text>
        </Box>
        <Box position='relative'>
          <Box className='keen-slider' as='div' listStyleType='none' ref={sliderRef as any}>
            {list.map(({ author, date, category, categoryUrl, caption, title, url, image, id, coAuthors }) => {
              const authors = [author, ...coAuthors]

              return (
                <Box key={id} as='div' className='keen-slider__slide'>
                  <Box as='article'>
                    <Link href={url} prefetch={false}>
                      <a href={url} title={title} target='_blank' rel='noreferrer'>
                        <Box position='relative'>
                          {isVideos && (
                            <Box position='absolute' fontSize='72px' color='white' right='24px' bottom='24px'>
                              <Play />
                            </Box>
                          )}
                          <Image
                            src={image}
                            width={1080}
                            height={495}
                            layout='responsive'
                            alt={caption || title}
                            objectFit='cover'
                          />
                        </Box>
                      </a>
                    </Link>
                    <Link href={url} prefetch={false}>
                      <a href={categoryUrl} title={category}>
                        <Text
                          textTransform='uppercase'
                          textStyle='collectionItemCategory'
                          mt={{
                            base: '30px',
                            md: '22px'
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: category }} />
                        </Text>
                      </a>
                    </Link>
                    <Link href={url} prefetch={false}>
                      <a href={url} title={title} target='_blank' rel='noreferrer'>
                        <Text
                          mt={{
                            base: '0px',
                            md: '0px'
                          }}
                          as='h3'
                          textStyle='collectionItemTitle'
                        >
                          <span dangerouslySetInnerHTML={{ __html: title }} />
                        </Text>
                      </a>
                    </Link>
                    <ArticleItemPostDate color='inherit' authors={authors} date={date} id={id} />
                  </Box>
                </Box>
              )
            })}
          </Box>
          {loaded && instanceRef && (
            <React.Fragment>
              {currentSlide !== 0 && (
                <Cue
                  onClick={() => {
                    handleCue('prev')
                  }}
                  disabled={currentSlide === 0}
                  arrow='prev'
                  position='absolute'
                  top={{
                    base: '160px',
                    md: '125px'
                  }}
                  mt={{
                    base: '-18px'
                  }}
                  left='16px'
                />
              )}
              {instanceRef && instanceRef.current?.track?.details.rel + slidePer < list.length && (
                <Cue
                  onClick={() => {
                    handleCue('next')
                  }}
                  position='absolute'
                  top={{
                    base: '160px',
                    md: '125px'
                  }}
                  mt={{
                    base: '-18px'
                  }}
                  right='16px'
                  arrow='next'
                />
              )}
            </React.Fragment>
          )}
        </Box>
        {seeAllUrl && (
          <Box mt='52px'>
            <Divider borderColor='border' mb='15px' />
            <SeeAllButton url={seeAllUrl} />
          </Box>
        )}
      </Box>
    </AppContainer>
  )
}
