import React from 'react'

function Play(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='currentColor' {...props}>
      <path d='M12 1C5.923 1 1 5.923 1 12c0 6.078 4.923 11 11 11 6.078 0 11-4.922 11-11 0-6.077-4.922-11-11-11zm3.52 11.303l-5.321 3.08a.34.34 0 01-.468-.124.366.366 0 01-.041-.165V8.92a.34.34 0 01.344-.344c.055 0 .123.014.178.041l5.322 3.08a.34.34 0 01.123.468.652.652 0 01-.137.138z' />
    </svg>
  )
}

export default Play
