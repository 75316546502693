/* eslint-disable */
import React from 'react'
import { Ad, Promotion, Promotions } from '../types'
import { AdSection, AddSectionPosition } from '../components'

export default function useAd(options: { onSendView?: (id: number) => void; promotions?: Promotions; key: string }) {
  const { key, promotions, onSendView } = options

  const promotion: Promotion | undefined = promotions?.[key]?.[0]

  const handleSendView = () => {
    if (promotion?.id && onSendView) {
      onSendView(promotion.id)
    }
  }

  let position: AddSectionPosition = 'alone'

  if (key === 'aside-top') {
    position = 'asideTop'
  }

  return {
    name: key,
    ad: promotion?.ad as Ad | undefined,
    handleSendView,
    autoRender: promotion?.ad ? (
      <AdSection onViewed={handleSendView} ad={promotion?.ad} position={position} positionName={key} />
    ) : (
      ''
    )
  }
}
