import React from 'react'
import Link from 'next/link'
import { Box, Text } from '@chakra-ui/layout'
import { useKeenSlider } from 'keen-slider/react'

import { PostRender } from '../../types'
import { AppContainer } from '../AppContainer'
import { SeeAllButton } from '../ArticleSection'
import config from '../../config'
import { ArticleBookmark } from '../ArticleBookmark'
import { useBreakpointValue } from '@krasia/core/media-query/use-breakpoint-value'
import { Divider } from '../Divider'
import Image from 'next/image'

export type BigImageProps = {
  list: PostRender[]
  title: string
  titleIcon: string
  seeAllUrl?: string
}

function BigImageItem(props: PostRender) {
  const { author, date, title, category, image, categoryUrl, url, caption, id } = props
  return (
    <Box as='article'>
      <Box>
        <Link href={url} prefetch={false}>
          <a href={url} title={title} target='_blank' rel='noreferrer'>
            <Image src={image} width={1175} height={500} layout='responsive' alt={caption || title} priority={true} />
          </a>
        </Link>
      </Box>
      <Link href={url} prefetch={false}>
        <a href={categoryUrl} title={category}>
          <Text textStyle='bigImageItemCategory' mt='30px'>
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Text>
        </a>
      </Link>
      <Link href={url} prefetch={false}>
        <a href={url} title={title} target='_blank' rel='noreferrer'>
          <Text
            mt={{
              base: '0px',
              md: '0px'
            }}
            as='h3'
            textStyle='bigImageItemTitle'
          >
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Text>
        </a>
      </Link>

      <Box mt='19px'>
        <ArticleBookmark
          id={id}
          author={author.name}
          authorLink={config.paths.authorDetail(author.nicename)}
          date={date}
        />
      </Box>
    </Box>
  )
}

export function BigImageSection({ list, title, titleIcon, seeAllUrl }: BigImageProps) {
  const device = useBreakpointValue<string>({ base: 'mobile', md: 'lower-desktop', lg: 'desktop' }) || 'desktop'
  let slidePer = 1.1
  let spacing = 20
  if (device === 'lower-desktop') {
    slidePer = 2.1
    spacing = 30
  }

  if (device === 'desktop') {
    slidePer = 3
    spacing = 50
  }

  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: slidePer,
      spacing
    }
  })
  return (
    <AppContainer
      pr={{ base: '0px', sm: '50px', md: '50px' }}
      pb={{
        base: '40px',
        md: '60px'
      }}
    >
      <Box as='section'>
        <Box as='header' mb='50px'>
          <Text as='h2' textStyle='sectionTitle' display='flex' alignItems='center'>
            {titleIcon && <Image objectFit='contain' width={26} height={26} alt={title} src={titleIcon} />}
            <span dangerouslySetInnerHTML={{ __html: title }} style={{ marginLeft: 16 }} />
          </Text>
        </Box>
        <Box className='keen-slider' as='div' listStyleType='none' ref={sliderRef as any}>
          {list.map(l => {
            return (
              <Box key={l.id} as='div' className='keen-slider__slide'>
                <BigImageItem {...l} />
              </Box>
            )
          })}
        </Box>
        {seeAllUrl && (
          <Box mt='80px'>
            <Divider borderColor='border' mb='15px' />
            <SeeAllButton url={seeAllUrl} />
          </Box>
        )}
      </Box>
    </AppContainer>
  )
}
