"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpointValue = void 0;
var media_query_utils_1 = require("./media-query.utils");
var use_breakpoint_1 = require("./use-breakpoint");
var utils_1 = require("@chakra-ui/utils");
var system_1 = require("@chakra-ui/system");
/**
 * React hook for getting the value for the current breakpoint from the
 * provided responsive values object.
 *
 * @example
 * const width = useBreakpointValue({ base: '150px', md: '250px' })
 */
function useBreakpointValue(values) {
    var breakpoint = (0, use_breakpoint_1.useBreakpoint)();
    var breakpoints = (0, system_1.useTheme)().breakpoints;
    if (!breakpoint) {
        return;
    }
    var obj = (0, utils_1.isArray)(values)
        ? (0, utils_1.fromEntries)(Object.entries((0, utils_1.arrayToObjectNotation)(values, breakpoints)
        // eslint-disable-next-line no-unused-vars
        ).map(function (_a) {
            var _ = _a[0], value = _a[1];
            return [value, value];
        }))
        : values;
    return (0, media_query_utils_1.getClosestValue)(obj, breakpoint);
}
exports.useBreakpointValue = useBreakpointValue;
