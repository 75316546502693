import { HStack, Text } from '@chakra-ui/layout'
import { Box } from '../Box'
import Link from 'next/link'
import React from 'react'
import { useKeenSlider } from 'keen-slider/react'
import { PostRender } from '../../types'
import { ArticleItemPostDate } from '../ArticleItemPostDate'
import Image from 'next/image'

export function SliderBannerItem(props: PostRender) {
  const { title, category, image, categoryUrl, url, caption, author, coAuthors, date, id } = props
  const authors = [author, ...coAuthors]

  return (
    <Box as='article'>
      <Box>
        <Link href={url} prefetch={false}>
          <a href={url} title={title} target='_blank' rel='noreferrer'>
            <Image
              src={`${image}!post.card.cover`}
              alt={caption || title}
              width={1080}
              height={500}
              layout={'responsive'}
              priority={true}
            />
          </a>
        </Link>
      </Box>
      <Link href={categoryUrl} prefetch={false}>
        <a href={categoryUrl} title={category}>
          <Text textAlign='center' textTransform='uppercase' textStyle='bannerCategory' mt='53px'>
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Text>
        </a>
      </Link>
      <Link href={url} prefetch={false}>
        <a href={url} title={title} target='_blank' rel='noreferrer'>
          <Text
            mt={{
              base: '12px',
              md: '10px'
            }}
            textAlign='center'
            as='h2'
            textStyle='bannerTitle'
          >
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Text>
        </a>
      </Link>
      <Box textAlign='center'>
        <ArticleItemPostDate justifyContent='center' authors={authors} date={date} id={id} isSameLine />
      </Box>
    </Box>
  )
}

export type SliderBannerIndicatorProps = {
  active?: boolean
  title: string
  onClick?: () => void
}

export function SliderBannerIndicator(props: SliderBannerIndicatorProps) {
  const { active, onClick, title } = props
  return (
    <Box
      onClick={onClick}
      py='10px'
      role='button'
      cursor='pointer'
      aria-label={`Article ${title} ${active && '(current)'}`}
    >
      <Box backgroundColor={!active ? 'sliderIndicator' : 'sliderIndicatorActive'} height={!active ? '2px' : '3px'} />
    </Box>
  )
}

export type SliderBannerProps = {
  list: PostRender[]
}

export function SliderBanner(props: SliderBannerProps) {
  const { list } = props
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,
      slides: {
        perView: 1
      }
    },
    [
      slider => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 5000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          slider.container.style.flexWrap = 'nowrap'
          slider.container.style.opacity = '1'
          nextTimeout()
        })

        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
        slider.on('slideChanged', slide => {
          setCurrentSlide(slide.track.details.rel)
        })
      }
    ]
  )

  return (
    <Box
      pb={{ base: '34px', md: '50px' }}
      width='100%'
      height={{ base: 'auto', sm: 'auto', md: '650px', lg: '610px' }}
      overflow='hidden'
    >
      <Box className='keen-slider' as='div' ref={sliderRef} style={{ opacity: '0', flexWrap: 'wrap' }}>
        {list.map(l => {
          return (
            <Box key={l.id} as='div' className='keen-slider__slide' style={{ display: 'block', width: '100%' }}>
              <SliderBannerItem {...l} />
            </Box>
          )
        })}
      </Box>
      {instanceRef && list.length > 1 && (
        <HStack as='div' listStyleType='none' spacing='10px' display='flex' justifyContent='center' mt='50px'>
          {list.map((l, index) => {
            return (
              <Box as='div' flexBasis='80px' key={l.id}>
                <SliderBannerIndicator
                  title={l.title}
                  active={currentSlide === index}
                  key={index}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(index)
                    setCurrentSlide(index)
                  }}
                />
              </Box>
            )
          })}
        </HStack>
      )}
    </Box>
  )
}
