import React from 'react'
import { Box, BoxProps } from '../Box'
import { Author } from '@krasia/core/types/collection'
import useShowTime from '../../utils/common'

export type ArticleItemPostDateProps = Omit<BoxProps, 'id'> & {
  date: number
  id: any
  authors?: Author[]
  isSameLine?: boolean
}

const SameLinePostDate = (props: Omit<ArticleItemPostDateProps, 'isSameLine'>) => {
  const { date, ...otherProps } = props

  const postDate = useShowTime(date)
  return (
    <Box mt='10px' display='flex' alignItems='center' flexWrap='wrap' color='text' {...otherProps}>
      <Box as='p' textStyle='articleItemAuthor'>
        {postDate}
      </Box>
    </Box>
  )
}

export function ArticleItemPostDate(props: ArticleItemPostDateProps) {
  const { id, date, authors, isSameLine, ...otherProps } = props
  const postDate = useShowTime(date)

  if (isSameLine) {
    return <SameLinePostDate id={id} date={date} authors={authors} {...otherProps} />
  }

  return (
    <Box mt={'8px'} {...otherProps}>
      <Box display='flex' alignItems='center' flexWrap='wrap' color='text'>
        <Box as='p' textStyle='articleItemAuthor'>
          {postDate}
        </Box>
      </Box>
    </Box>
  )
}
