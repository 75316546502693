import React from 'react'
import { Box } from '../Box'
import useShowTime from '../../utils/common'

export type ArticleBookmarkProps = {
  date: number
  id: number
  authorLink: string
  author: string
}

export function ArticleBookmark(props: ArticleBookmarkProps) {
  const { date } = props

  const postDate = useShowTime(date)
  return (
    <Box display='flex'>
      <Box>
        <Box as='p' textStyle='articleItemAuthor' color='postDate'>
          {postDate}
        </Box>
      </Box>
    </Box>
  )
}
