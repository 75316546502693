import Link from 'next/link'
import React from 'react'
import { Text } from '@chakra-ui/layout'
import { ChakraProps } from '@chakra-ui/system'
import { Box } from '../Box'
import { Divider } from '../Divider'
import { PostRender } from '../../types'
import config from '../../config'
import { ArticleBookmark } from '../ArticleBookmark'
import { ArticleItemPostDate } from '../ArticleItemPostDate'
import SeeAll from '../../icons/SeeAll'
import Image from 'next/image'
import { useMediaQuery } from '@chakra-ui/react'

export type ArticleSectionItemProps = {
  variant?: string
  title: string
  url: string
  category?: string
  categoryUrl?: string
}

export function ArticleSectionItem(props: PostRender & { variant?: string }) {
  const { category, title, categoryUrl, url, author, date, id, coAuthors, variant } = props
  const authors = [author, ...coAuthors]

  return (
    <Box as='article' py={['40px', '20px']}>
      {category && !categoryUrl && <Text textStyle='sectionArticleCategory'>{category}</Text>}
      {category && categoryUrl && (
        <Link href={categoryUrl} prefetch={false}>
          <Box
            color={variant === 'highlight' ? 'white' : 'articleCategory'}
            as='a'
            href={categoryUrl}
            textStyle='sectionArticleCategory'
          >
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Box>
        </Link>
      )}
      <Text as='h3' textStyle='sectionArticleTitle' mt={{ base: '5px', md: '0px' }}>
        <Link href={url} prefetch={false}>
          <a href={url} title={title} target='_blank' rel='noreferrer'>
            <Box as='span' lineHeight='28px' dangerouslySetInnerHTML={{ __html: title }} />
          </a>
        </Link>
      </Text>
      <ArticleItemPostDate color='inherit' authors={authors} date={date} id={id} />
    </Box>
  )
}

export function ArticleSectionRowItem(props: PostRender) {
  const { category, title, categoryUrl, url, image, caption, id, author, date, coAuthors } = props
  const authors = [author, ...coAuthors]
  const [isMobile] = useMediaQuery('(max-width: 768px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  }) // 用于判断是否是移动端

  const renderCategory = () => (
    <>
      {category && !categoryUrl && (
        <Text
          mt={{
            base: '-5px',
            md: '16px'
          }}
          textStyle='sectionArticleCategory'
        >
          <span dangerouslySetInnerHTML={{ __html: category }} />
        </Text>
      )}
      {category && categoryUrl && (
        <Link href={categoryUrl} prefetch={false}>
          <Box
            mt={{
              base: '-5px',
              md: '0'
            }}
            display='flex'
            as='a'
            href={categoryUrl}
            textStyle='sectionArticleGridCategory'
          >
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Box>
        </Link>
      )}
    </>
  )

  return (
    <Box
      as='article'
      display={{
        base: 'flex',
        md: 'flex'
      }}
      flexDirection={{
        base: 'row-reverse'
        // md: 'row-reverse'
      }}
      justifyContent='space-between'
      pb={{ base: '12px', md: '30px' }}
    >
      <Box
        flexBasis={{
          base: 120,
          md: 240
        }}
        mt={{
          base: '10px',
          md: '0px'
        }}
      >
        <Link href={url} prefetch={false}>
          <a href={url} title={title} target='_blank' rel='noreferrer'>
            <Image
              src={image ? `${image}` : '/images/onboarding-option-writer.jpg'}
              width={1080}
              height={560}
              layout='responsive'
              alt={caption || title}
              priority={true}
              objectFit='cover'
            />
          </a>
        </Link>
      </Box>
      <Box pr={{ base: '12px', md: '50px' }} flex={'1'}>
        {!isMobile && renderCategory()}
        <Text
          as='h3'
          textStyle='sectionArticleTitle'
          mt={{ base: '5px', md: '0px' }}
          fontSize={{ base: '16px', md: '18px' }}
          lineHeight={{ base: '24px', md: '28px' }}
        >
          <Link href={url} prefetch={false}>
            <a href={url} title={title} target='_blank' rel='noreferrer'>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </a>
          </Link>
        </Text>
        <Box mt={'12px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          {isMobile && renderCategory()}
          <ArticleItemPostDate color='inherit' authors={authors} date={date} id={id} marginTop={'0px'} />
        </Box>
      </Box>
    </Box>
  )
}

export function ArticleSectionGridItem(props: PostRender) {
  const { author, category, date, title, categoryUrl, url, image, caption, excerpt, id } = props
  return (
    <Box
      as='article'
      display={{
        base: 'flex',
        md: 'block'
      }}
      flexDirection={{
        base: 'column-reverse'
      }}
    >
      <Box
        flexBasis='30%'
        minWidth='30%'
        mt={{
          base: '16px',
          md: '0px'
        }}
      >
        <Link href={url} prefetch={false}>
          <a href={url} title={title} target='_blank' rel='noreferrer'>
            <Image
              src={image ? `${image}!post.card` : '/images/onboarding-option-writer.jpg'}
              width={1080}
              height={495}
              layout='responsive'
              alt={caption || title}
              priority={true}
            />
          </a>
        </Link>
        <Text as='p' textStyle='excerpt' mt='16px' display={{ md: 'none' }}>
          <span dangerouslySetInnerHTML={{ __html: excerpt }} />
        </Text>
      </Box>
      <Box>
        {category && !categoryUrl && (
          <Text
            mt={{
              base: '-5px',
              md: '22px'
            }}
            textStyle='sectionArticleCategory'
          >
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Text>
        )}
        {category && categoryUrl && (
          <Link href={categoryUrl} prefetch={false}>
            <Box
              mt={{
                base: '-5px',
                md: '22px'
              }}
              display='flex'
              as='a'
              href={categoryUrl}
              textStyle='sectionArticleGridCategory'
            >
              <span dangerouslySetInnerHTML={{ __html: category }} />
            </Box>
          </Link>
        )}
        <Text as='h3' textStyle='sectionArticleTitle' mt={{ base: '5px', md: '0px' }}>
          <Link href={url} prefetch={false}>
            <a href={url} title={title} target='_blank' rel='noreferrer'>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </a>
          </Link>
        </Text>
        <Box mt='19px'>
          <ArticleBookmark
            id={id}
            author={author.name}
            authorLink={config.paths.authorDetail(author.nicename)}
            date={date}
          />
        </Box>
      </Box>
    </Box>
  )
}

export type SeeAllButtonProps = {
  url: string
  seeAllText?: string
}

export function SeeAllButton({ url, seeAllText = '全部' }: SeeAllButtonProps) {
  return (
    <Link href={url} prefetch={false}>
      <Box
        href={url}
        as='a'
        textStyle='sectionArticleSeeAll'
        alignItems='center'
        display='flex'
        pb='20px'
        borderColor='seeAllBorder'
        justifyContent='flex-end'
      >
        {seeAllText}
        <Box
          ml='14px'
          fontSize={{
            base: '30px'
          }}
        >
          <SeeAll />
        </Box>
      </Box>
    </Link>
  )
}

export type ArticleSectionProps = ChakraProps &
  React.HTMLAttributes<HTMLElement> & {
    icon?: React.ReactNode
    iconImage?: string
    title: React.ReactNode
    list: PostRender[]
    seeAllUrl?: string
    displayStyle?: 'grid'
  }

export function renderDisplayGrid(list: PostRender[]) {
  return (
    <Box>
      <Box
        as='ul'
        listStyleType='none'
        display={{
          base: 'block',
          md: 'flex'
        }}
        ml={{
          base: 0,
          md: '-50px'
        }}
        mt={{ base: '-30px', md: '-35px' }}
        flexWrap='wrap'
      >
        {list.map(l => (
          <Box
            py={{ base: '30px', md: '35px' }}
            pl={{
              base: 0,
              md: '50px'
            }}
            as='li'
            key={l.id}
            flexBasis='50%'
          >
            <ArticleSectionGridItem {...l} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export function renderDisplayRow(list: PostRender[]) {
  return (
    <Box>
      <Box as='ul' listStyleType='none'>
        {list.map(l => (
          <Box pt={{ base: '12px', md: '30px' }} as='li' key={l.id}>
            <ArticleSectionRowItem {...l} />
            <Divider borderColor='border' />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export function ArticleSection(props: ArticleSectionProps) {
  const { icon, displayStyle, iconImage, title, list, seeAllUrl, ...otherProps } = props
  return (
    <Box as='section' {...otherProps}>
      <Box
        as='header'
        mb={{
          base: '40px',
          md: '25px'
        }}
      >
        <Text as='h2' textStyle='sectionTitle' display='flex' mb='15px' alignItems='center'>
          {icon && (
            <Box mt='2px' fontSize='1em' mr='16px'>
              {icon}
            </Box>
          )}
          {iconImage && (
            <Box mr='16px'>
              <Image objectFit='contain' width={26} height={26} alt='' src={iconImage} />
            </Box>
          )}
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Text>
      </Box>
      {!displayStyle && (
        <React.Fragment>
          <Divider borderColor='border' />
          <Box as='ul' listStyleType='none'>
            {list.map(l => (
              <Box as='li' key={l.id}>
                <ArticleSectionItem {...l} />
                <Divider borderColor='border' />
              </Box>
            ))}
          </Box>
          {seeAllUrl && (
            <Box mt='25px'>
              <SeeAllButton url={seeAllUrl} />
            </Box>
          )}
        </React.Fragment>
      )}
      {displayStyle === 'grid' && renderDisplayGrid(list)}
    </Box>
  )
}
